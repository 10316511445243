import request from './request'

const api = {
	// 查看指定管理员详情
	info: '/admin/info',
	// 列表
	lists: '/admin/lists',
	// 添加
	add: '/admin/add',
	// 获取角色列表
	rolelist: '/group/lists',
	// 编辑
	edit: '/admin/edit',
	// 删除
	dele: '/admin/del',
	// 村列表
	villageList: '/village/lists',
	// 重置密码
	resetpwd: '/admin/reset',
	// 获取系统配置
	getConfig: '/system/getConfig',
	// 获取统计接口
	statistics: '/system/statistics',
}

export {
	request,
	api
}
